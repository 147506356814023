<template>
  <div class="project-case">
    <img
      class="pic1"
      referrerpolicy="no-referrer"
      src="@/assets/project-case/banner.png"
    />
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "ProjectCase",
};
</script>

<style lang="stylus" scoped>
.project-case{
  padding-top 80px
  padding-bottom: 160px;
}

.pic1 {
  height: 600px;
  width: 100%;
  display: block;
}

</style>